<template>
  <div v-if="dialog" class="relative z-50">
    <div class="fixed inset-0 bg-black bg-opacity-80"></div>
    <div class="fixed inset-0 overflow-auto">
      <div class="flex items-center justify-center h-full">
        <div class="bg-white rounded-lg p-4 w-full max-w-sm md:max-w-md lg:max-w-md">
          <div>
            <div class="font-bold mt-2">
              <div class="flex justify-center text-center text-2xl font-bold">Disconnect?</div>
              <div class="flex justify-center text-center text-lg font-semibold">
                Are you sure to disconnect
                <br />your DANA account
              </div>
              <div class="flex justify-center gap-8">
                <button
                  @click="cancel"
                  class="w-20 text-center bg-white text-primary border border-primary font-semibold py-2 rounded-lg">
                  Cancel
                </button>
                <button @click="confirm" class="w-20 text-center bg-primary text-white font-semibold py-2 rounded-lg">
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalUnlink",
  props: {
    dialog: Boolean,
  },
  methods: {
    confirm() {
      this.$emit("handle-unlink", true);
    },
    cancel() {
      this.$emit("handle-unlink", false);
    },
  },
};
</script>
